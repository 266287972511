<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>leagues</label>
          </b-col>

          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <ChangeLeaguePosition
              :list="listData"
              type="tipping"
              @change-position="handleChangePosition"
            />
            <b-button
              size="sm"
              variant="primary mr-1 ml-0.5"
              @click="handleShowModalAddLeague"
            >
              <span class="ml-25 align-middle">Add New League</span>
            </b-button>

          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refLeaguesListTable"
        :items="fetchLeagues"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchLeagues.length === 0 && !loading"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: logo -->
        <template #cell(logo)="{ item }">
          <b-img
            v-if="item.logo"
            style="width: 30px; height: 30px;"
            :src="item.logo"
            alt="logo"
          />
        </template>

        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="resolveStatusVariant(item.status)"
            >
              {{ (item.status) }}
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showModal('disable', item)">
              <feather-icon
                v-if="item.status !== 'active'"
                icon="UnlockIcon"
              />
              <feather-icon
                v-else
                icon="LockIcon"
              />

              <span class="align-middle ml-50">{{ item.status !== 'active' ? 'Enable' : 'Disable' }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="showModal('delete', item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showModalEditLeague(item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showModalChangePosition(item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Change Comp Position </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} leagues
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLeagues"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isSubmitting"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- ADD NEW LEAGUE -->

    <b-modal
      ref="add-league"
      :title="isEdit ? 'Edit League' : 'Add League'"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="lg"
      @hide="hideModal"
    >
      <section style="color: black">
        <p style="font-weight: 500; font-size: 18px;">
          Please  FILL and check that link is correct?
        </p>
        <p
          style=" width: fit-content;"
        >
          https://gateway.prod.pricekinetics.com.au/sports/summary/<span style="color: red; font-weight: bold;">{{ sportCode ? sportCode : 'sportCode' }}</span>?api_key=018e4fbb-adc0-7b67-a76c-ec6b8d756f82&days=30&league=<span style="color: red; font-weight: bold;">{{ sportLeagueId ? sportLeagueId : 'sportLeagueId' }}</span>
        </p>
        <!-- spoggLeagueId -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              sportLeagueId
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="sportLeagueId"
              type="text"
              placeholder="eg. NRL"
              :disabled="isEdit"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorSportLeagueId"
          class="text-danger"
        >{{ errorSportLeagueId }}</small>

        <!-- leagueName -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              leagueName
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="leagueName"
              type="text"
              placeholder="eg. Ruby League"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorLeagueName"
          class="text-danger"
        >{{ errorLeagueName }}</small>

        <!-- sportCode -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              sportCode
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="sportCode"
              type="text"
              placeholder="eg. rugby_league"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorSportCode"
          class="text-danger"
        >{{ errorSportCode }}</small>

        <!-- logo -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Logo Image
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center"
          >
            <b-button
              e
              variant="primary"
              size="sm"
              class="mr-75"
              style="width: 100px;"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .gif, .heic, .jpeg"
              :hidden="true"
              plain
              @change="inputImageRenderer"
            />
            <b-img
              v-if="logo"
              style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px;"
              :src="logo"
              alt="logo"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingUpdate"
              @click="handleSubmitNewLeague"
            >
              <Spinner v-if="loadingUpdate" />
              <template v-else>
                Save
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>

    <!-- CHANGE POSITION LIST COMP -->
    <b-modal
      ref="position"
      title="Change list position"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="listComp.length === 0"
      @ok="handleSubmitChangePosition"
    >
      <b-row
        v-if="sportSelected"
        style="color: black;"
        class="mb-0 ml-0"
      >
        <h4>Sport: {{ sportSelected.sportLeagueId }}</h4>

      </b-row>
      <b-row v-if="listComp.length > 0">
        <b-col md="12">
          <draggable
            :list="listComp"
            tag="ul"
            group="people"
            class=" cursor-move prevent-select "
            style="padding: 0 !important;"
          >
            <b-list-group-item
              v-for="(item, index) in listComp"
              :key="index"
              tag="li"
              class="list"
            >
              <div class="d-flex">
                <b-media vertical-align="center">
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold d-block text-nowrap text-body">
                      {{ `${index + 1}, CompId ${item.competitionId}: ${ item.name} ($${item.price})` }}
                    </div>
                  </div>
                </b-media>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
      </b-row>
      <b-row
        v-else
        class="d-flex justify-content-center mt-2 mb-2"
      >
        <h4 style="text-align: center;">
          No comp in list
        </h4>
      </b-row>
      <template #modal-ok>
        <span>Save</span>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import { avatarText } from '@core/utils/filter'
import { formatEventDate, formatDateTimeAustralia, toCardinal } from '@/helpers/utils'
import {
  BCard,
  BCol,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardText,
  BButton,
  BFormInput,
  BFormFile,
  BImg,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'

import { mapActions, mapState } from 'vuex'
import { computed, ref, watch } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'
import ChangeLeaguePosition from './ChangeLeaguePosition.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardText,

    ChangeLeaguePosition,
    BButton,
    BFormInput,
    BFormFile,
    BImg,
    Spinner,
    BListGroupItem,
    draggable,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    // isRefresh: Boolean,
  },
  data() {
    return {
      type: '',
      formatDateTimeAustralia,
      modal: {
        title: '',
        content: '',
      },
      listData: [],
      sportLeagueId: null,
      errorSportLeagueId: '',
      leagueName: null,
      errorLeagueName: '',
      sportCode: null,
      errorSportCode: '',
      logo: '',
      isEdit: false,
      editItem: null,

      listComp: [],
      sportSelected: null,
    }
  },
  computed: {
    ...mapState('races/sport', [
      'loadingUpdate', 'loading',
    ]),
    isSubmitting() {
      return this.loadingUpdate
    },
  },
  watch: {
    sportLeagueId(val) {
      if (val && this.errorSportLeagueId) this.errorSportLeagueId = ''
    },
    leagueName(val) {
      if (val && this.errorLeagueName) this.errorLeagueName = ''
    },
    sportCode(val) {
      if (val && this.errorSportCode) this.errorSportCode = ''
    },
  },

  methods: {
    ...mapActions({
      getListLeagues: 'tipping/league/getListLeagues',
      handleUpdateLeague: 'tipping/league/handleUpdateLeague',
      handleDeleteLeague: 'tipping/league/handleDeleteLeague',
      uploadFlag: 'sportComp/createComp/uploadFlag',
      handleAddNewLeague: 'tipping/league/handleAddNewLeague',
      getListComp: 'races/listComp/getListComp',
      getListCompPosition: 'races/listComp/getListCompPosition',
      updateSortListComp: 'races/listComp/updateSortListComp',
    }),
    async fetchLeagues() {
      const data = await this.getListLeagues()

      this.listData = data

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching leagues list')
      this.totalLeagues = data.length

      return data
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    showModal(type, item) {
      this.action = { item, type }
      if (type === 'disable') {
        this.setModal('Disable League', `Are you sure you want to ${item.status ? 'enable' : 'disable'} this league?`)
      }
      if (type === 'delete') this.setModal('Delete League', 'Are you sure you want to delete this league?')

      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      const { item, type } = this.action

      if (type === 'disable') await this.updateStatusLeague(item)
      if (type === 'delete') await this.deleteLeague(item)

      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    async updateStatusLeague(item) {
      const result = await this.handleUpdateLeague({
        sportLeagueId: item.sportLeagueId,
        leagueName: item.leagueName,
        sportLeagueCode: item.leagueName,
        status: item.status === 'active' ? 'disable' : 'active',
      })
      if (!result) return
      this.showToastSuccess('Success', 'Change status league successfully!')
      this.refLeaguesListTable.refresh()
    },
    async deleteLeague(item) {
      const result = await this.handleDeleteLeague(item.sportLeagueId)
      if (!result) return
      this.showToastSuccess('Success', 'Delete league successfully!')
      this.refLeaguesListTable.refresh()
    },
    handleChangePosition() {
      this.refLeaguesListTable.refresh()
    },
    async inputImageRenderer(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        this.fileImage = file
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          this.logo = res
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    handleShowModalAddLeague() {
      this.$refs['add-league'].show()
    },
    hideModal() {
      this.$refs['add-league'].hide()

      this.resetModal()
      this.isEdit = false
    },
    resetModal() {
      this.sportLeagueId = null
      this.errorSportLeagueId = ''
      this.leagueName = null
      this.errorLeagueName = ''
      this.sportCode = null
      this.errorSportCode = ''
      this.logo = ''
    },
    async handleSubmitNewLeague(event) {
      event.preventDefault()
      if (!this.sportLeagueId) this.errorSportLeagueId = 'sportLeagueId is required'
      if (!this.leagueName) this.errorLeagueName = 'leagueName is required'
      if (!this.sportCode) this.errorSportCode = 'sportCode is required'
      if (this.errorSportLeagueId || this.errorLeagueName || this.errorSportCode) return
      if (this.isEdit) return this.saveEditLeague()
      const res = await this.handleAddNewLeague({
        sportLeagueId: this.sportLeagueId,
        leagueName: this.leagueName,
        sportCode: this.sportCode,
        ...(this.logo && { logo: this.logo }),
      })
      if (res) {
        this.showToastSuccess('Success', 'Create new league successfully!')
        this.hideModal()
        this.handleChangePosition()
      }
    },
    showModalEditLeague(item) {
      this.isEdit = true
      this.sportLeagueId = item.sportLeagueId
      this.leagueName = item.leagueName
      this.sportCode = item.sportCode
      this.logo = item.logo
      this.$refs['add-league'].show()
    },
    async saveEditLeague() {
      const result = await this.handleUpdateLeague({
        sportLeagueId: this.sportLeagueId,
        leagueName: this.leagueName,
        sportLeagueCode: this.leagueName,
        logo: this.logo,
        sportCode: this.sportCode,
      })
      if (!result) return
      this.showToastSuccess('Success', 'Update league successfully!')
      this.hideModal()
      this.handleChangePosition()
    },
    async handleSubmitChangePosition() {
      const res = await this.updateSortListComp({
        competitionIds: this.listComp.map(i => i.competitionId),
        sportLeagueId: this.sportSelected.sportLeagueId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Update order list successfully!')
      }
    },
    async showModalChangePosition(item) {
      this.sportSelected = item
      const res = await this.getListCompPosition({
        limit: 20,
        page: 1,
        sortField: 'orderNumber',
        sortValue: 'DESC',
        type: 'sport-tipping',
        endDateFrom: new Date().toISOString(),
        sportLeagueId: item.sportLeagueId,

      })
      this.listComp = res
      this.$refs.position.show()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const refLeaguesListTable = ref(null)

    const tableColumns = [
      { key: 'sportLeagueId' },
      { key: 'leagueName', label: 'Name' },
      { key: 'sportCode', label: 'Sport Code' },
      { key: 'status' },
      { key: 'logo' },
      { key: 'actions' },
    ]

    const perPage = ref(100)
    const totalLeagues = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]

    const resolveStatusVariant = status => {
      if (status === 'active') return 'light-success'
      return 'light-warning'
    }

    const dataMeta = computed(() => {
      const localItemsCount = refLeaguesListTable.value ? refLeaguesListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalLeagues.value,
      }
    })

    const refetchData = () => {
      refLeaguesListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    return {
      getUserData,

      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refetchData,

      avatarText,
      showToastError,
      showToastSuccess,
      isRoleSuperAdmin,
      formatEventDate,
      totalLeagues,
      refLeaguesListTable,
      toCardinal,
      resolveStatusVariant,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
      width: 90px;
  }

  .text-title {
    color: black;
    font-weight: 500;
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
